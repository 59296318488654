const MOBILE_NAVIGATION_ROUTES = {
  LOGIN: 'mobile-login',
  REGISTRATION: 'mobile-registration',
  RESTORE: 'mobile-restore',
  PROFILE: 'mobile-profile'
};

const MOBILE_APP_LINKS = {
  android: 'nurKz://authSuccess',
  ios: 'nurKz://authSuccess'
};

const LOGOUT_APP_LINKS = {
  android: 'nurkz://logout',
  ios: 'nurkz://logout'
};

const MOBILE_ACTIONS = {
  LOGIN: 'login',
  PROFILE: 'profile',
  WATCH_LOGOUT: 'watchLogout'
};

const MOBILE_AUTH_STEPS = {
  SUCCESS: 'success'
};

const createLinkToMobileRegistration = () =>
  `${location.origin}/${MOBILE_NAVIGATION_ROUTES.REGISTRATION}/${location.search}`;
const createLinkToMobileRestore = () => `${location.origin}/${MOBILE_NAVIGATION_ROUTES.RESTORE}/${location.search}`;
const createLinkToMobileLogin = () => `${location.origin}/${MOBILE_NAVIGATION_ROUTES.LOGIN}/${location.search}`;
const createLinkToMobileProfile = () => `${location.origin}/${MOBILE_NAVIGATION_ROUTES.PROFILE}/${location.search}`;

const redirectToMobileLogout = mobilePlatform => {
  window.location.href = LOGOUT_APP_LINKS[mobilePlatform];
};

const redirectToMobileProfile = () => {
  window.location.href = createLinkToMobileProfile();
};

export {
  MOBILE_NAVIGATION_ROUTES,
  MOBILE_APP_LINKS,
  LOGOUT_APP_LINKS,
  MOBILE_ACTIONS,
  MOBILE_AUTH_STEPS,
  createLinkToMobileLogin,
  createLinkToMobileRestore,
  createLinkToMobileRegistration,
  createLinkToMobileProfile,
  redirectToMobileProfile,
  redirectToMobileLogout
};
