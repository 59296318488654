import {existsCookie, setCookie} from '../../services/cookie';

const COOKIE_ENABLED = 'is_cookie_enabled';
const TEN_SECONDS = 10000;
const DAYS = 30;
const HOURS_IN_A_DAY = 24;
const HOURS_IN_30_DAYS = DAYS * HOURS_IN_A_DAY;

const initCookiePopup = () => {
  if (existsCookie(COOKIE_ENABLED)) {
    return;
  }

  const cookiePopupElement = document.querySelector('.js-cookie-popup');

  if (cookiePopupElement) {
    setCookie(COOKIE_ENABLED, true, HOURS_IN_30_DAYS);
    cookiePopupElement.classList.remove('cookie-popup--hidden');

    setTimeout(() => {
      cookiePopupElement.classList.add('cookie-popup--hidden');
    }, TEN_SECONDS);

    document.querySelector('.js-cookie-popup__button')?.addEventListener('click', () => {
      cookiePopupElement.classList.add('cookie-popup--hidden');
    });
  }
};

export default initCookiePopup;
