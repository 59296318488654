export const JWT = 'gn_jwt';
export const NUR_TOKEN = 'token';
export const CALLBACK_URL = 'gn_cb_url';

export const SUCCESS_LOGIN = 'success_login';

export const NUR_LOGIN_ACTION = 'nur_auth';

export const RATING_API = `${window.Site.ratingDomain}api/`;

export const VOTE_POSITIVE = 'positive';

export const VOTE_CONFIRM = 'confirm';

export const VOTE_NEGATIVE = 'negative';

export const VOTE_SUCCESS = 'success';

export const MODAL_ERROR = 'error';

export const MODAL_AUTH = 'auth';

export const MODAL_ABOUT = 'about';

export const MODAL_VOTE_SUCCESS = 'success';
export const MODAL_VOTE_CONFIRM = 'confirm';
export const MODAL_ADD_PHONE = 'addPhoneModal';
export const DEFAULT_RATING = 0;
export const DEFAULT_VOTES = 0;
export const PLATFORM = {
  desktop: window.innerWidth > 1152,
  mobile: window.innerWidth <= 1152
};
export const SUPPRESS_CONFIRM = 'no_confirm';
export const CURRENT_ORIGIN = document.location.origin;
export const CURRENT_BASE = '/golos-naroda/';
export const CURRENT_BASE_TRIM = '/golos-naroda';
export const SHOW_MODAL_FROM_LOGIN_PAGE = 'showModalFromLoginPage';
