import {
  displayLoginButton,
  displayLogoutButton,
  initListenerAuthButton
} from './modules/auth-buttons';
import initCookiePopup from './modules/cookie-popup';
import {LogoutTracker} from './services/analytics/auth/logout';
import initUserTypeDimension from './services/analytics/user';
import {checkControllerServiceWorker} from './services/helpers';
import hiveApi from './services/hive-sdk';
import {sendOnesignalUserIdToApi} from './services/onesignal-helpers';
import {
  applyRefreshToken,
  getAccessToken,
  getTokenPayload,
  isTokenValid,
  removeAuthorizationData} from './services/tokens';
import {createUser} from './website-ui/models/user';
import {initUserMemoryRepository} from './website-ui/repositories/user-memory-repository';
import {initGoogleAuth} from './google-auth';

initListenerAuthButton();
initUserMemoryRepository();

const accessToken = getAccessToken();
const isAuthorized = isTokenValid(accessToken);

if (isAuthorized) {
  displayLogoutButton();
  sendOnesignalUserIdToApi(hiveApi.sendPushUserId, accessToken);
  const {id, username, role} = getTokenPayload(accessToken);
  const user = createUser({
    isAuthenticated: true,
    id,
    username,
    role
  });

  window.userMemoryRepository.set(user);
} else {
  applyRefreshToken(
    () => {
      displayLogoutButton();
    },
    () => {
      initGoogleAuth();
      displayLoginButton();
    },
    () => {
      LogoutTracker.systemAction();
      removeAuthorizationData();
    });
}

initUserTypeDimension(isAuthorized);
checkControllerServiceWorker();
initCookiePopup();
