function store(obj) {
  const subscribers = Symbol('subscribers');

  const isSetExists = window.Set;

  const targetObject = {
    ...obj,
    [subscribers]: isSetExists ? new Set() : [],
    subscribe(subscriber) {
      if (isSetExists && subscriber && {}.toString.call(subscriber) === '[object Function]') {
        targetObject[subscribers].add(subscriber);
        return () => targetObject[subscribers].delete(subscriber);
      }
    }
  };

  return new Proxy(targetObject, {
    set(target, property, value, receiver) {
      if (!window.Reflect) {
        return null;
      }
      const success = Reflect.set(target, property, value, receiver);

      if (success) {
        target[subscribers].forEach(handler => handler(property, value));
      } else {
        console.error('Cannot call the handler with unset settings');
      }
      return success;
    }
  });
}

export {
  store
};
