import queryString from 'query-string';

import {NOTIFICATION_VARIANTS} from '@nur-portal/ui-kit';

import {GOOGLE_AUTH_SOURCE_STORAGE_NAME, GOOGLE_AUTH_SOURCES} from './constants/google-auth';
import {FEATURE_NAMES} from './growthbook/constants';
import {handleLoginSuccess} from './modules/auth-buttons';
import {NAVIGATION_ROUTES} from './modules/auth-pages/constants';
import {publishEvent} from './services/analytics/pub-sub';
import {ANALYTIC_EVENTS} from './services/analytics/pub-sub/constants';
import {loadScript} from './services/helpers';
import hiveSdk from './services/hive-sdk';
import {getStorageUserId} from './services/user-id-helpers';
import {showNotification} from './website-ui/components/notification-center/helpers';
import {handleCommonError, isCommonError} from './website-ui/helpers/common-error';
import i18n from './website-ui/i18n/config.js';

const locale = window.Site?.targeting?.section === 'kaz' ? 'kk' : 'ru';

const GOOGLE_CLIENT_INIT_EVENT_NAME = 'GOOGLE_CLIENT_INIT';

const GOOGLE_ONE_TAP_SOURCES = ['user', 'fedcm', 'fedcm_auto', 'user_1tap', 'user_2tap'];

const getRedirectUrl = () => {
  const isAuthRoute = Object.values(NAVIGATION_ROUTES).some(
    navigationRoute => window.location.pathname.includes(navigationRoute)
  );

  if (isAuthRoute) {
    return '/';
  }

  return window.location.href;
};

const sendGoogleSuccessEvent = source => {
  if (GOOGLE_ONE_TAP_SOURCES.includes(source)) {
    publishEvent(ANALYTIC_EVENTS.SIGN_UP_WITH_ONE_TAP_SUCCESS);
    return;
  }

  if (location.pathname.includes(NAVIGATION_ROUTES.REGISTRATION)) {
    publishEvent(ANALYTIC_EVENTS.SIGN_UP_WITH_GOOGLE_SUCCESS, {
      isRegistration: true
    });
    return;
  }

  if (location.pathname.includes(NAVIGATION_ROUTES.LOGIN)) {
    publishEvent(ANALYTIC_EVENTS.SIGN_UP_WITH_GOOGLE_SUCCESS, {
      isRegistration: false
    });
    return;
  }

  const authSource = localStorage.getItem(GOOGLE_AUTH_SOURCE_STORAGE_NAME) || '';

  switch (authSource) {
    case GOOGLE_AUTH_SOURCES.ARTICLE_REACTIONS:
      publishEvent(ANALYTIC_EVENTS.ARTICLE_REACTIONS_SUCCESS_GOOGLE);
      return;
    case GOOGLE_AUTH_SOURCES.PREGNANCY_WIDGET:
      publishEvent(ANALYTIC_EVENTS.PREGNANT_GOOGLE_LOGIN_SUCCESS);
      return;
    default:
      return;
  }
};

const defaultSuccessCallback = data => {
  hiveSdk.signUpGoogle(
    {
      ...data,
      state: getStorageUserId()
    },
    response => {
      showNotification({
        variant: NOTIFICATION_VARIANTS.SUCCESS,
        text: i18n.t('loginPage:loginViaPhone.notifications.success')
      });

      sendGoogleSuccessEvent(data?.select_by);

      handleLoginSuccess(response);
      const parsedSearch = queryString.parse(decodeURIComponent(location.search)) || {};

      window.location.href = parsedSearch.redirectURL || getRedirectUrl();
    },
    responseError => {
      if (isCommonError(responseError.code)) {
        handleCommonError(i18n.t, responseError.code);
      }
    },
    null
  );
};

const initGoogleAuth = (
  successCallback = defaultSuccessCallback,
  shouldRenderOneTap = true
) => {
  const googleClientId = window?.Site?.googleClientId;

  const isOneTapFeatureEnabled = window.growthBook && window.growthBook.evalFeature(FEATURE_NAMES.ONE_TAP)?.value;

  loadScript(
    'oauth',
      `https://accounts.google.com/gsi/client?hl=${locale}`,
      () => {
        if (window.google && window.google.accounts) {
          window.google.accounts.id.initialize({
            client_id: googleClientId,
            callback: successCallback,
            use_fedcm_for_prompt: true,
            cancel_on_tap_outside: false,
            itp_support: true,
            context: 'continue',
            ux_mode: 'popup',
            auto_select: false
          });
          window.dispatchEvent(new CustomEvent(GOOGLE_CLIENT_INIT_EVENT_NAME));
          localStorage.removeItem(GOOGLE_AUTH_SOURCE_STORAGE_NAME);
          if (shouldRenderOneTap && isOneTapFeatureEnabled) {
            window.google.accounts.id.prompt();
          }
        }
      },
      null);
};

export {
  GOOGLE_CLIENT_INIT_EVENT_NAME,
  initGoogleAuth
};
