import queryString from 'query-string';

import {JWT} from '../golos/constants';
import {LogoutTracker} from '../services/analytics/auth/logout';
import {addJitsuProperty} from '../services/analytics/jitsu-helpers';
import {publishEvent} from '../services/analytics/pub-sub';
import {ANALYTIC_EVENTS} from '../services/analytics/pub-sub/constants';
import {
  applyRefreshToken,
  getAccessToken,
  getRefreshToken,
  isTokenValid,
  removeAuthorizationData
} from '../services/tokens';
import {setAuthorizationDataAndUserId} from '../services/user-id-helpers';
import {MOBILE_ACTIONS, redirectToMobileLogout} from '../website-ui/pages/mobile/constants';

import {createLinkToLogin} from './auth-pages/constants';

const AUTH_EVENT = 'nur_auth';
const AUTH_LOGIN_MESSAGE = 'success_login';
const AUTH_LOGOUT_MESSAGE = 'success_logout';

const $iconLogged = document.querySelector('.js-icon-logged');
const $iconUnlogged = document.querySelector('.js-icon-unlogged');
const $authTextBtn = document.querySelector('.js-auth-btn-text');

const displayLogoutButton = () => {
  if ($authTextBtn && $iconUnlogged && $iconLogged) {
    $authTextBtn.textContent = $authTextBtn?.dataset?.loggedText;
    $authTextBtn.classList.add('nonVisibleOnMobile');
    $iconUnlogged.classList.add('nonvisible');
    $iconUnlogged.classList.remove('nonVisibleOnMobile');
    $iconUnlogged.classList.remove('visible');
    $iconLogged.classList.add('visible');
    $iconLogged.classList.remove('nonvisible');
    $iconLogged.style.display = '';
    $iconUnlogged.style.display = '';
  }
};

const displayLoginButton = () => {
  if ($authTextBtn && $iconUnlogged && $iconLogged) {
    $authTextBtn.textContent = $authTextBtn?.dataset?.unloggedText;
    $authTextBtn.classList.add('visible');
    $authTextBtn.classList.remove('nonVisibleOnMobile');
    $iconUnlogged.classList.add('visible');
    $iconUnlogged.classList.add('nonVisibleOnMobile');
    $iconUnlogged.classList.remove('nonvisible');
    $iconLogged.classList.add('nonvisible');
    $iconLogged.classList.remove('visible');
    $iconLogged.style.display = '';
    $iconUnlogged.style.display = '';
  }
};

const authTriggerEvent = data => {
  window.dispatchEvent(new CustomEvent(AUTH_EVENT, data));
};

// eslint-disable-next-line no-warning-comments
// TODO: remove TMP code or rewrite. Its just for POC
const handleLogoutRedirect = () => {
  const parsedSearch = queryString.parse(decodeURIComponent(window.location.search)) || {};
  const mobilePlatform = parsedSearch.mobilePlatform || '';
  const mobileAction = parsedSearch.mobileAction || '';

  if (mobileAction === MOBILE_ACTIONS.WATCH_LOGOUT) {
    redirectToMobileLogout(mobilePlatform);
  } else {
    window.location.href = '/';
  }
};

const handleLogout = (withRedirect = true) => {
  removeAuthorizationData();
  displayLoginButton(); // eslint-disable-line no-use-before-define
  window.CoralEmbed?.logout();
  authTriggerEvent({detail: AUTH_LOGOUT_MESSAGE});
  LogoutTracker.userAction();
  if (withRedirect) {
    handleLogoutRedirect();
  }
  localStorage.removeItem(JWT);
};

const handleLoginRedirect = event => {
  event.target.disabled = true;
  const query = window.location.pathname !== '/' ? {
    redirectURL: window.location.pathname
  } : {};

  window.location.href = createLinkToLogin(query);
};

const handleLoginSuccess = response => {
  setAuthorizationDataAndUserId(true, response);
  addJitsuProperty('is_authenticated', true);
  displayLogoutButton();
  authTriggerEvent({detail: AUTH_LOGIN_MESSAGE});
};

const initListenerAuthButton = () => {
  const $authBtn = document.querySelector('.js-auth-btn');

  $authBtn?.addEventListener('click', event => {
    if (isTokenValid(getAccessToken())) {
      publishEvent(ANALYTIC_EVENTS.PROFILE_BUTTON_CLICK);
      window.location.href = '/profile';
      return null;
    }
    if (isTokenValid(getRefreshToken())) {
      applyRefreshToken(
        () => {
          window.location.href = '/profile';
        },
        displayLoginButton,
        () => {
          LogoutTracker.systemAction();
          removeAuthorizationData();
          handleLoginRedirect(event);
        }
      );

      return null;
    }
    publishEvent(ANALYTIC_EVENTS.HEADER_BUTTON_CLICK);
    handleLoginRedirect(event);
  });
};

export {
  AUTH_EVENT,
  AUTH_LOGIN_MESSAGE,
  AUTH_LOGOUT_MESSAGE,
  displayLogoutButton,
  displayLoginButton,
  authTriggerEvent,
  handleLogout,
  handleLoginSuccess,
  initListenerAuthButton
};
