import {AUTH_EVENT, AUTH_LOGIN_MESSAGE} from '../../modules/auth-buttons';

import {ANALYTIC_EVENTS} from './pub-sub/constants';
import {publishEvent} from './pub-sub';

const AUTHORIZED_USER_TYPE = 'authorized user';
const UNAUTHORIZED_USER_TYPE = 'unauthorized user';

const initUserTypeDimension = isAuthorized => {
  const dimensionValue = isAuthorized ? AUTHORIZED_USER_TYPE : UNAUTHORIZED_USER_TYPE;

  publishEvent(ANALYTIC_EVENTS.SET_USER_TYPE, {
    userType: dimensionValue
  });

  window.addEventListener(AUTH_EVENT, e => {
    const newDimensionValue = e?.detail === AUTH_LOGIN_MESSAGE ? AUTHORIZED_USER_TYPE : UNAUTHORIZED_USER_TYPE;

    publishEvent(ANALYTIC_EVENTS.SET_USER_TYPE, {
      userType: newDimensionValue
    });
  });
};

export default initUserTypeDimension;
