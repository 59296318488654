import {userContract} from '../models/user';
import {store} from '../utils/shared/store';

const userStore = store({user: userContract});

class UserMemoryRepository {
  constructor() {
    this.subscribe = userStore.subscribe;
    if (!UserMemoryRepository.instance) {
      UserMemoryRepository.instance = this;
    }

    return UserMemoryRepository.instance;
  }

  get() {
    return userStore.user;
  }

  set(user) {
    userStore.user = user;
  }

  clear() {
    userStore.user = userContract;
  }
}

function initUserMemoryRepository () {
  window.userMemoryRepository = window.userMemoryRepository || new UserMemoryRepository();
}

export {userStore, initUserMemoryRepository};
