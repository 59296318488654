import queryString from 'query-string';

import {
  createLinkToMobileProfile,
  MOBILE_ACTIONS,
  MOBILE_APP_LINKS,
  MOBILE_NAVIGATION_ROUTES
} from '../../website-ui/pages/mobile/constants';

const NAVIGATION_ROUTES = {
  LOGIN: 'login',
  REGISTRATION: 'registration',
  RESTORE: 'password-recovery'
};

const MAIN_PAGE_REDIRECT_URLS = Object.values(NAVIGATION_ROUTES);

const createSearch = query => {
  const queryResult = query && Boolean(Object.keys(query).length)
    ? `?${queryString.stringify(query)}`
    : '';

  const shouldClearQuery = query?.redirectURL && MAIN_PAGE_REDIRECT_URLS.some(
    url => query?.redirectURL?.includes(`/${url}`)
  );

  return shouldClearQuery ? '' : queryResult;
};

const getMobileAuthData = () => {
  const parsedSearch = queryString.parse(decodeURIComponent(location.search)) || {};

  const mobileAction = parsedSearch.mobileAction?.replace('/', '');
  const mobilePlatform = parsedSearch.mobilePlatform?.replace('/', '');

  return {
    mobileAction,
    mobilePlatform,
    isMobileAuth: Boolean(mobileAction && mobilePlatform)
  };
};

const createLinkToRestore = query => {
  const {isMobileAuth} = getMobileAuthData();
  const route = isMobileAuth ? MOBILE_NAVIGATION_ROUTES.RESTORE : NAVIGATION_ROUTES.RESTORE;

  return `${location.origin}/${route}/${createSearch(query)}`;
};

const createLinkToRegistration = query => `${location.origin}/${NAVIGATION_ROUTES.REGISTRATION}/${createSearch(query)}`;

const createLinkToLogin = query => {
  const {isMobileAuth} = getMobileAuthData();
  const route = isMobileAuth ? MOBILE_NAVIGATION_ROUTES.LOGIN : NAVIGATION_ROUTES.LOGIN;

  return `${location.origin}/${route}/${createSearch(query)}`;
};

const buildRedirectUrl = (defaultUrl, data) => {
  const {isMobileAuth, mobileAction, mobilePlatform} = getMobileAuthData();

  if (!isMobileAuth) {
    return defaultUrl;
  }

  if (mobilePlatform && mobileAction === MOBILE_ACTIONS.PROFILE) {
    return createLinkToMobileProfile();
  }

  const link = new URL(MOBILE_APP_LINKS[mobilePlatform]);

  link.searchParams.append('accessToken', data.access_token);
  link.searchParams.append('refreshToken', data.refresh_token);

  return link.href;
};

export {
  NAVIGATION_ROUTES,
  createLinkToLogin,
  createLinkToRestore,
  createLinkToRegistration,
  buildRedirectUrl,
  getMobileAuthData
};
