const ONESIGNAL_USER_ID_NAME = 'onesignalUserId';

const getOnesignalUserId = (onSuccess, onError = () => {}) => {
  window.OneSignal?.push(() => window.OneSignal.getUserId().then(onSuccess).catch(onError));
};

const setOnesignalUserIdToLocalStorage = userId => localStorage.setItem(ONESIGNAL_USER_ID_NAME, userId);

const getOnesignalUserIdFromLocalStorage = () => localStorage.getItem(ONESIGNAL_USER_ID_NAME);

const checkUserIdAndSendData = (sendDataToApi, accessToken) => userId => {
  if (!userId || userId === getOnesignalUserIdFromLocalStorage()) {
    return null;
  }

  return sendDataToApi(
    {onesignalId: userId},
    () => setOnesignalUserIdToLocalStorage(userId),
    e => console.error(e, 'Fail send OneSignal userId'),
    accessToken
  );
};

export const sendOnesignalUserIdToApi = (sendDataToApi, accessToken) => {
  getOnesignalUserId(checkUserIdAndSendData(sendDataToApi, accessToken));
};
